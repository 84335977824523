import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScaleLoader from "react-spinners/ScaleLoader";

import AuthContext from "../../store/auth-context";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import classes from "./UserDetailPage.module.css";
import StyledLink from "../../components/StyledLink/StyledLink";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

const UserDetailPage = () => {
  // User data state.
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState("");
  const [userEnabled, setUserEnabled] = useState("");
  const [userCreatedDate, setUserCreatedDate] = useState("");
  const [userLastModifiedDate, setUserLastModifiedDate] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [userDataLoading, setUserDataLoading] = useState(false);

  // Subscription data state.
  const [subscriptionActive, setSubscriptionActive] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [totalSpent, setTotalSpent] = useState(0);
  const [stripeId, setStripeId] = useState("");
  const [currency, setCurrency] = useState("");
  const [payments, setPayments] = useState([]);
  const [subscriptionDataLoading, setSubscriptionDataLoading] = useState(false);

  // UserAttributes data state.
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState([]);
  const [userAttributesDataLoading, setUserAttributesDataLoading] =
    useState(false);

  // User Checklists state.
  const [checklists, setChecklists] = useState([]);
  const [checklistsLoading, setChecklistsLoading] = useState(false);

  // User Orders state.
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);

  const { userId } = useParams();
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  // Get user data.
  useEffect(() => {
    const loadUserData = async () => {
      try {
        const session = await Auth.currentSession();
        let url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/users/${userId}`;
        const config = {
          headers: {
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
          },
        };
        setUserDataLoading(true);
        axios
          .get(url, config)
          .then((res) => {
            const userData = res.data;

            // Set email and emailVerified
            if (userData.Attributes) {
              userData.Attributes.forEach((attribute) => {
                if (attribute.Name === "email_verified") {
                  setEmailVerified(attribute.Value === "true" ? true : false);
                } else if (attribute.Name === "email") {
                  setEmail(attribute.Value);
                }
              });
            }
            // Set userEnabled
            if (userData.Enabled !== undefined) {
              setUserEnabled(userData.Enabled);
            }
            // Set userCreatedDate
            if (userData.UserCreateDate) {
              setUserCreatedDate(userData.UserCreateDate);
            }
            // Set userLastModifiedDate
            if (userData.UserLastModifiedDate) {
              setUserLastModifiedDate(userData.UserLastModifiedDate);
            }
            // Set userStatus
            if (userData.UserStatus) {
              setUserStatus(userData.UserStatus);
            }
            setUserDataLoading(false);
          })
          .catch((err) => {
            handleAxiosError(err);
            setUserDataLoading(false);
          });
      } catch (err) {
        setUserDataLoading(false);
        console.error(err);
        handleAuthError(err, navigate, authCtx);
      }
    };
    loadUserData();
  }, [
    navigate,
    authCtx,
    userId,
    handleAuthError,
    setEmailVerified,
    setEmail,
    setUserEnabled,
    setUserCreatedDate,
    setUserLastModifiedDate,
    setUserStatus,
    setUserDataLoading,
  ]);

  // Get subscription data.
  useEffect(() => {
    const loadSubscriptionData = async () => {
      try {
        const session = await Auth.currentSession();
        let url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/subscriptions/user/${userId}`;
        const config = {
          headers: {
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
          },
        };
        setSubscriptionDataLoading(true);
        axios
          .get(url, config)
          .then((res) => {
            const subscriptionData = res.data;

            // Set subscriptionActive
            if (subscriptionData.subscription_active !== undefined) {
              setSubscriptionActive(
                subscriptionData.subscription_active ? "Yes" : "No"
              );
            }
            // Set subscriptionType
            if (subscriptionData.subscription_type) {
              setSubscriptionType(subscriptionData.subscription_type);
            }
            // Set totalSpent
            if (subscriptionData.total_spent !== undefined) {
              setTotalSpent(subscriptionData.total_spent);
            }
            // Set stripeId
            if (subscriptionData.stripe_customer_id) {
              setStripeId(subscriptionData.stripe_customer_id);
            }
            // Set currency
            if (subscriptionData.currency) {
              setCurrency(subscriptionData.currency);
            }
            // Set payments
            if (subscriptionData.payments) {
              setPayments(subscriptionData.payments);
            }
            setSubscriptionDataLoading(false);
          })
          .catch((err) => {
            handleAxiosError(err);
            setSubscriptionDataLoading(false);
          });
      } catch (err) {
        setSubscriptionDataLoading(false);
        console.error(err);
        handleAuthError(err, navigate, authCtx);
      }
    };
    loadSubscriptionData();
  }, [
    ,
    navigate,
    authCtx,
    userId,
    handleAuthError,
    setSubscriptionActive,
    setSubscriptionType,
    setTotalSpent,
    setStripeId,
    setCurrency,
    setPayments,
    setSubscriptionDataLoading,
  ]);

  // Get UserAttribute data.
  useEffect(() => {
    const loadUserAttributeData = async () => {
      try {
        const session = await Auth.currentSession();
        let url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/userattributes/cognito_user_id/${userId}`;
        const config = {
          headers: {
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
          },
        };
        setUserAttributesDataLoading(true);
        axios
          .get(url, config)
          .then((res) => {
            if (res.data.Item !== undefined) {
              setHowDidYouHearAboutUs(res.data.Item.howDidYouHearAboutUs);
            }
            setUserAttributesDataLoading(false);
          })
          .catch((err) => {
            if (err.response.status === 404) {
              // User hasnt answered the survey
              setUserAttributesDataLoading(false);
            } else {
              handleAxiosError(err);
            }
          });
      } catch (err) {
        setUserAttributesDataLoading(false);
        console.error(err);
        handleAuthError(err, navigate, authCtx);
      }
    };
    loadUserAttributeData();
  }, [
    setHowDidYouHearAboutUs,
    navigate,
    authCtx,
    handleAuthError,
    setUserAttributesDataLoading,
  ]);

  // Get Checklist data.
  useEffect(() => {
    const loadChecklists = async () => {
      try {
        const session = await Auth.currentSession();
        let url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/user/${userId}`;
        const config = {
          headers: {
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
          },
        };
        setChecklistsLoading(true);
        axios
          .get(url, config)
          .then((res) => {
            setChecklists(res.data.Items);
            setChecklistsLoading(false);
          })
          .catch((err) => {
            if (err.response.status === 404) {
              console.error(err);
              // User hasnt answered the survey
              setChecklistsLoading(false);
            } else {
              handleAxiosError(err);
            }
          });
      } catch (err) {
        setChecklistsLoading(false);
        console.error(err);
        handleAuthError(err, navigate, authCtx);
      }
    };
    loadChecklists();
  }, [setChecklists, navigate, authCtx, handleAuthError, setChecklistsLoading]);

  // Get user Orders.
  useEffect(() => {
    const loadOrders = async () => {
      try {
        const session = await Auth.currentSession();
        let url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/orders/cognito/${userId}`;
        const config = {
          headers: {
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
          },
        };
        setOrdersLoading(true);
        axios
          .get(url, config)
          .then((res) => {
            setOrders(res.data.Items);
            setOrdersLoading(false);
          })
          .catch((err) => {
            handleAxiosError(err);
            setOrdersLoading(false);
          });
      } catch (err) {
        setOrdersLoading(false);
        console.error(err);
        handleAuthError(err, navigate, authCtx);
      }
    };
    loadOrders();
  }, [setOrders, navigate, authCtx, handleAuthError, setOrdersLoading]);

  const getDateString = (timestamp) => {
    const date = new Date(timestamp * 1000); // UNIX timestamp is in seconds, Javascript timestamp is in milliseconds.
    return `${date.getUTCFullYear()}/${
      date.getUTCMonth() + 1
    }/${date.getUTCDate()}`;
  };

  const getRoundedPrice = (price) => {
    return (price / 100).toFixed(2);
  };

  const getTotalSpentString = (totalSpent, currency) => {
    return `$${getRoundedPrice(totalSpent)} ${currency.toUpperCase()}`;
  };

  const loader = <ScaleLoader color="#4f4fee" />;
  const rowStyle = { "&:last-child td, &:last-child th": { border: 0 } };

  const userDetailsTable = (
    <ErrorBoundary>
      <TableContainer component={Paper}>
        <Table className={classes.dataTable}>
          <TableHead></TableHead>
          <TableBody>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                User ID
              </TableCell>
              <TableCell align="right">{userId}</TableCell>
            </TableRow>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Email
              </TableCell>
              <TableCell align="right">{email}</TableCell>
            </TableRow>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Email Verified
              </TableCell>
              <TableCell align="right">
                {emailVerified ? "Yes" : "No"}
              </TableCell>
            </TableRow>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Enabled
              </TableCell>
              <TableCell align="right">{userEnabled ? "Yes" : "No"}</TableCell>
            </TableRow>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Created
              </TableCell>
              <TableCell align="right">{userCreatedDate}</TableCell>
            </TableRow>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Last Modified
              </TableCell>
              <TableCell align="right">{userLastModifiedDate}</TableCell>
            </TableRow>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Status
              </TableCell>
              <TableCell align="right">{userStatus}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ErrorBoundary>
  );

  const userAttributesTable =
    howDidYouHearAboutUs.length === 0 ? (
      <p>User hasn't answered the survey...</p>
    ) : (
      <ErrorBoundary>
        <TableContainer component={Paper}>
          <Table className={classes.dataTable}>
            <TableBody>
              {howDidYouHearAboutUs.map((attribute) => {
                if (attribute.type === "existing") {
                  return (
                    <TableRow key={attribute.type} sx={rowStyle}>
                      <TableCell component="th" scope="row">
                        Existing Choice
                      </TableCell>
                      <TableCell align="right">{attribute.option}</TableCell>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow sx={rowStyle}>
                      <TableCell component="th" scope="row">
                        Other Choice
                      </TableCell>
                      <TableCell align="right">
                        {attribute.description}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ErrorBoundary>
    );

  const subscriptionDetailsTable = (
    <ErrorBoundary>
      <TableContainer component={Paper}>
        <Table className={classes.dataTable}>
          <TableBody>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Subscription Active
              </TableCell>
              <TableCell align="right">{subscriptionActive}</TableCell>
            </TableRow>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Subscription Type
              </TableCell>
              <TableCell align="right">{subscriptionType}</TableCell>
            </TableRow>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Total Spent
              </TableCell>
              <TableCell align="right">
                {getTotalSpentString(totalSpent, currency)}
              </TableCell>
            </TableRow>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Stripe ID
              </TableCell>
              <TableCell align="right">{stripeId || "N/A"}</TableCell>
            </TableRow>
            <TableRow sx={rowStyle}>
              <TableCell component="th" scope="row">
                Currency
              </TableCell>
              <TableCell align="right">
                {currency.toUpperCase() || "N/A"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ErrorBoundary>
  );

  const checklistsTable =
    checklists.length === 0 ? (
      <p>No checklists to show...</p>
    ) : (
      <ErrorBoundary>
        <TableContainer
          sx={{ width: "90%", margin: "10px auto" }}
          component={Paper}
        >
          <Table className={classes.checklistsTable}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">ID</TableCell>
                <TableCell align="right">Created (UTC)</TableCell>
                <TableCell align="right">Last Modified (UTC)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checklists.map((checklist) => {
                return (
                  <TableRow key={checklist.id} sx={rowStyle}>
                    <TableCell component="th" scope="row">
                      <StyledLink to={`/checklists/${checklist.id}`}>
                        {checklist.name}
                      </StyledLink>
                    </TableCell>
                    <TableCell align="right">{checklist.id}</TableCell>
                    <TableCell align="right">{checklist.createdDate}</TableCell>
                    <TableCell align="right">
                      {checklist.lastModifiedDate}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ErrorBoundary>
    );

  const ordersTable =
    orders.length === 0 ? (
      <p>No orders to show...</p>
    ) : (
      <ErrorBoundary>
        <TableContainer
          sx={{ width: "90%", margin: "10px auto" }}
          component={Paper}
        >
          <Table className={classes.ordersTable}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Created (UTC)</TableCell>
                <TableCell align="right">Last Modified (UTC)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => {
                return (
                  <TableRow key={order.id} sx={rowStyle}>
                    <TableCell component="th" scope="row">
                      <StyledLink to={`/admin/orders/${order.id}`}>
                        {order.id}
                      </StyledLink>
                    </TableCell>
                    <TableCell align="right">{order.status}</TableCell>
                    <TableCell align="right">{order.createdDate}</TableCell>
                    <TableCell align="right">
                      {order.lastModifiedDate}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ErrorBoundary>
    );

  const paymentsTable =
    payments.length === 0 ? (
      <p>No payments to show...</p>
    ) : (
      <ErrorBoundary>
        <TableContainer
          sx={{ width: "90%", margin: "10px auto" }}
          component={Paper}
        >
          <Table className={classes.paymentsTable}>
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell align="right">Amount Captured</TableCell>
                <TableCell align="right">Amount Refunded</TableCell>
                <TableCell align="right">Currency</TableCell>
                <TableCell align="right">Date (UTC)</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Paid</TableCell>
                <TableCell align="right">Refunded</TableCell>
                <TableCell align="right">ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((payment) => (
                <TableRow
                  key={payment.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    ${getRoundedPrice(payment.amount)}
                  </TableCell>
                  <TableCell align="right">
                    ${getRoundedPrice(payment.amount_captured)}
                  </TableCell>
                  <TableCell align="right">
                    ${getRoundedPrice(payment.amount_refunded)}
                  </TableCell>
                  <TableCell align="right">
                    {payment.currency.toUpperCase()}
                  </TableCell>
                  <TableCell align="right">
                    {getDateString(payment.created)}
                  </TableCell>
                  <TableCell align="right">
                    {payment.description ? payment.description : "N/A"}
                  </TableCell>
                  <TableCell align="right">
                    {payment.paid ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="right">
                    {payment.refunded ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="right">{payment.id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ErrorBoundary>
    );

  return (
    <div>
      <div className={classes.details}>
        <h2>User</h2>
        {userDataLoading ? loader : userDetailsTable}
      </div>
      <div className={classes.details}>
        <h2>How did they hear about us?</h2>
        {userAttributesDataLoading ? loader : userAttributesTable}
      </div>
      <div className={classes.details}>
        <h2>Subscription</h2>
        {subscriptionDataLoading ? loader : subscriptionDetailsTable}
      </div>
      <div className={classes.checklists}>
        <h2>Checklists</h2>
        {checklistsLoading ? loader : checklistsTable}
      </div>
      <div className={classes.checklists}>
        <h2>Orders</h2>
        {ordersLoading ? loader : ordersTable}
      </div>
      <div className={classes.payments}>
        <h2>Payments</h2>
        {subscriptionDataLoading ? loader : paymentsTable}
      </div>
    </div>
  );
};

export default UserDetailPage;
