import classes from "./CustomerDisplay.module.css";

const CustomerDisplay = () => {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <p className={classes.title}>Trusted By</p>
        <a
          className={classes.logoContainer}
          href="https://www.flywiseaviation.com"
        >
          <img
            className={classes.logo}
            src="images/customers/flywiseaviation.png"
          />
        </a>
      </div>
    </div>
  );
};

export default CustomerDisplay;
