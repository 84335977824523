import { useState, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import classes from "./AdminOrdersPage.module.css";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import AuthContext from "../../store/auth-context";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import StyledLink from "../../components/StyledLink/StyledLink";

const AdminOrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState("");
  const [reloadOrders, setReloadOrders] = useState(false);

  // Pagination state
  const [count, setCount] = useState(0);
  const [approximateTotalCount, setApproximateTotalCount] = useState(0);
  const [paginationToken, setPaginationToken] = useState("");
  const [currPaginationToken, setCurrPaginationToken] = useState("");
  const [prevPaginationTokens, setPrevPaginationTokens] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setReloadOrders(false);
    Auth.currentAuthenticatedUser()
      .then((res) => {
        setIsLoading(true);
        let url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/orders`;

        // Add paginationToken if we have one.
        if (currPaginationToken !== "" && createdBy === "") {
          url += `?lastkey=${encodeURIComponent(currPaginationToken)}`;
        }
        // Set createdby if it's used
        if (createdBy !== "") {
          url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/orders/cognito/${createdBy}`;
          resetPagingState();
        }

        const config = {
          headers: {
            Authorization: `Bearer ${res.signInUserSession.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((res) => {
            setOrders(res.data.Items);
            setCount(res.data.Count);
            setApproximateTotalCount(res.data.ApproximateTotalCount);
            setPaginationToken(
              res.data.LastEvaluatedKey ? res.data.LastEvaluatedKey.id : ""
            );
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  }, [authCtx, navigate, reloadOrders, handleAuthError]);

  const getShortDateString = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toDateString();
  };

  const goToNextPage = () => {
    if (!paginationToken) return;
    let newPrevPaginationTokens = [...prevPaginationTokens];
    newPrevPaginationTokens.push(currPaginationToken);
    setPrevPaginationTokens(newPrevPaginationTokens);
    setCurrPaginationToken(paginationToken);
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage === 0) return;
    let newPrevPaginationTokens = [...prevPaginationTokens];
    const newCurrPaginationToken = newPrevPaginationTokens.pop();
    const newPaginationToken = currPaginationToken;
    setPrevPaginationTokens(newPrevPaginationTokens);
    setCurrPaginationToken(newCurrPaginationToken);
    setPaginationToken(newPaginationToken);
    setCurrentPage(currentPage - 1);
  };

  const resetPagingState = () => {
    setPaginationToken("");
    setCurrPaginationToken("");
    setPrevPaginationTokens([]);
    setCurrentPage(0);
  };

  const handleCreatedByChange = (event) => {
    setCreatedBy(event.target.value);
  };

  const searchBar = (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      marginTop="20px"
      paddingX="20px"
      className={classes.searchBar}
    >
      <TextField
        label="Created By"
        variant="outlined"
        size="small"
        onChange={handleCreatedByChange}
      />
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          setReloadOrders(true);
        }}
      >
        Search
      </Button>
    </Stack>
  );

  const pageNavigator = (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      marginBottom="20px"
    >
      <IconButton onClick={goToPreviousPage} disabled={currentPage === 0}>
        <NavigateBeforeIcon />
      </IconButton>
      <div className={classes.pageNumber}>
        <div>{currentPage + 1}</div>
      </div>
      <IconButton onClick={goToNextPage} disabled={!paginationToken}>
        <NavigateNextIcon />
      </IconButton>
    </Stack>
  );

  const ordersTable =
    orders.length === 0 ? (
      <p>No orders to show</p>
    ) : (
      <ErrorBoundary>
        <TableContainer component={Paper} className={classes.ordersTable}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Stripe Customer ID</TableCell>
                <TableCell align="right">Created</TableCell>
                <TableCell align="right">Created By</TableCell>
                <TableCell align="right">Last Updated</TableCell>
                <TableCell align="right">Last Updated By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">
                    <StyledLink to={`/admin/orders/${order.id}`}>
                      {order.id}
                    </StyledLink>
                  </TableCell>
                  <TableCell align="right">{order.status}</TableCell>
                  <TableCell align="right">{order.email}</TableCell>
                  <TableCell align="right">
                    {order.stripe_customer_id}
                  </TableCell>
                  <TableCell align="right">
                    {getShortDateString(order.createdDate)}
                  </TableCell>
                  <TableCell align="right">{order.createdBy}</TableCell>
                  <TableCell align="right">
                    {getShortDateString(order.lastModifiedDate)}
                  </TableCell>
                  <TableCell align="right">{order.lastModifiedBy}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ErrorBoundary>
    );

  const loadingBackdrop = (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.topRow}>
          <div className={classes.backlink}>
            <NavLink to="/admin">
              <ArrowBackIcon />
              <span>Back</span>
            </NavLink>
          </div>
          <h1 className={classes.title}>Orders</h1>
        </div>
        <p className={classes.title}>Page Total: {count}</p>
        <p className={classes.title}>
          Approximate Total: {approximateTotalCount}
        </p>
        {searchBar}
        <ErrorBoundary>{ordersTable}</ErrorBoundary>
        {pageNavigator}
      </div>
      {loadingBackdrop}
    </div>
  );
};

export default AdminOrdersPage;
